
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useContext, useEffect, useState } from 'react';
import { STORAGE_KEYS } from '../../../utils/config';
import environment from '../../../utils/environment';
import { currencyFormatter } from '../../../utils/format';
import IconCalendar from './icon/IconCalendar';
import IconClock from './icon/IconClock';
import IconDollar from './icon/IconDollar';
import { addKeyToImage, setStorage } from '../../../utils/utils';
import AppContext from '../../../store/AppContext';

const Item = ({ item, openSellPopup, openRentPopup, openOrderTrialPopup, setOpenSignUp, setIdOrderTrialPopup, setAdditionalId, height = "h-full", objectFit = "cover", width = "", setUrl = null }: any) => {
    const { windowWidth } = useContext(AppContext);
    const router = useRouter();
    const [images, setImages] = useState(["/asset/image/image-2.png", "/asset/image/customer_1.webp"]);
    const [renderImage, setRenderImage] = useState(item.file ? environment.baseImageUrl + addKeyToImage(item.file.image_url, windowWidth, false, false, true) : "/asset/image/No_Image_Available.webp");
    const [host, setHost] = useState("");
    const [onRender, setOnRender] = useState(false);
    const alt_def = item?.file?.alt ? item?.file?.alt : "HGS";
    function onMouseUp() {
        setTimeout(() => {
            setOnRender(true);
        }, 500)
    }

    function onMouseOut() {
        setOnRender(false);
        //setRenderImage(images[0]);

    }

    function onCheckAlreadySignUp() {
        let name = localStorage.getItem(STORAGE_KEYS.name);
        let email = localStorage.getItem(STORAGE_KEYS.email);
        let phone = localStorage.getItem(STORAGE_KEYS.phone);
        if (!name || !phone) {
            setIdOrderTrialPopup(item.id);
            setOpenSignUp(true);
            return false
        } else {
            openOrderTrialPopup(item.id)
        }
        return true
    }

    useEffect(() => {
        let index = 0;

    }, [onRender]);

    useEffect(() => {
        setHost(window.location.origin);
    }, [])

    return <div
        key={item.id}
        className='relative shadow_product_item w-full flex flex-col'
        onMouseOver={onMouseUp}
        onMouseLeave={onMouseOut}>
        <Link
            rel="noopener noreferrer"
            className={width + " " + height + " z-[10] relative flex flex-col lg:flex-col-reverse product_item shadow-md lg:shadow-transparent aspect-[167/345]  lg:aspect-[293/400] overflow-hidden "}
            title={item.name}
            href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
        >
            <Image
                loading='lazy'
                alt={alt_def}
                src={renderImage}
                title={alt_def}
                fill
                style={{
                    objectFit: objectFit
                    //backgroundImage: `url(${renderImage})`,
                    // backgroundSize: `${objectFit}`,
                    // backgroundRepeat: "no-repeat",
                    // backgroundPosition: "center center"
                }}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                className={" flex-1 flex flex-col-reverse relative overflow-hidden "}/>
            <Link
                rel="noopener noreferrer"
                title={item.name}
                href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
                onClick={() => {
                    setStorage(item.id, item.name);
                    if (setAdditionalId) {

                        setAdditionalId(item.id, item.name)
                    }
                    //router.push(item?.url?.replace(/^\/+/, ''));

                }} className="hidden lg:flex product_item_bottom p-[8px] lg:p-[16px] h-[104px] md:h-[120px] lg:h-[140px] overflow-hidden z-[50]">
                <div className='h-[36px] lg:h-[48px] mb-3'>
                    <p className="header_content break_2 uppercase text-sm md:text-base text-[#434343] lg:text-[#fff]">{item?.name ?? ""}</p>
                </div>
                <div className="flex flex-col gap-1 md:gap-2">
                    <span className={"content inline text-xs md:text-sm text-[#434343] lg:text-[#fff] " + (item?.rent_price ? "" : "hidden")}>Thuê <span className='inline content text-xs md:text-sm text-[#FF4D4F] lg:text-[#fff]'>{item?.rent_price ? currencyFormatter.format(item?.rent_price) : ""}</span>
                    </span>
                    <span className={"content inline text-xs md:text-sm text-[#434343] lg:text-[#fff] " + (item?.purchase_price ? "" : "hidden")}>Bán <span className='inline content text-xs md:text-sm text-[#FF4D4F] lg:text-[#fff]'>{currencyFormatter.format(item?.purchase_price)}</span>
                    </span>
                </div>
            </Link>
            <Link

                rel="noopener noreferrer"
                title={item.name}
                href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
                onClick={() => {
                    setStorage(item.id, item.name);
                    if (setAdditionalId) {

                        setAdditionalId(item.id, item.name)
                    }
                    //router.push(item?.url?.replace(/^\/+/, ''));

                }} className="flex lg:hidden product_item_bottom p-[8px] lg:p-[16px] h-[104px] md:h-[120px] lg:h-[140px] overflow-hidden z-[50]">
                <div className='h-[36px] lg:h-[48px] mb-3'>
                    <p className="header_content break_2 uppercase text-sm md:text-base text-[#434343] lg:text-[#fff]">{item?.name ?? ""}</p>
                </div>
                <div className="flex flex-col gap-1 md:gap-2">
                    <span className={"content inline text-xs md:text-sm text-[#434343] lg:text-[#fff] " + (item?.rent_price ? "" : "hidden")}>Thuê <span className='inline content text-xs md:text-sm text-[#FF4D4F] lg:text-[#fff]'>{item?.rent_price ? currencyFormatter.format(item?.rent_price) : ""}</span>
                    </span>
                    <span className={"content inline text-xs md:text-sm text-[#434343] lg:text-[#fff] " + (item?.purchase_price ? "" : "hidden")}>Bán <span className='inline content text-xs md:text-sm text-[#FF4D4F] lg:text-[#fff]'>{currencyFormatter.format(item?.purchase_price)}</span>
                    </span>
                </div>
            </Link>
        </Link>

        <div
            className="cart hidden lg:flex bottom-6 right-6 absolute z-[24]">
            <Image
                priority={true}
                src="/asset/icon/ico_cart.svg"
                alt="Giỏ hàng"
                title='cart'
                className="m-auto"
                width={24}
                height={24}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
        </div>
        <div id='product_actions_layout' className='product_actions_layout z-[25] '>
            <button onClick={() => {
                onCheckAlreadySignUp()
            }} className="product_actions">
                <div className='product_actions--circle'>
                    <IconCalendar className={"m-auto ico_action"} />
                </div>
                <p className='text-center'>Đặt lịch thử đồ</p>
            </button>
            <button disabled={item.allowed_sell === false ? true : false} onClick={() => openRentPopup(item.id, item?.is_product, true)} className={"product_actions " + (item.allowed_sell === false ? " cursor-not-allowed " : "")}>
                <div className='product_actions--circle'>
                    <IconClock className={"m-auto ico_action"} />
                </div>
                <p className='text-center'>Đặt thuê</p>
            </button>
            <button onClick={() => openSellPopup(item.id, item?.is_product, true)} className="product_actions">
                <div className='product_actions--circle'>
                    <IconDollar className={"m-auto ico_action"} />
                </div>
                <p className='text-center'>Đặt mua</p>
            </button>

        </div>
        <div className="number_cart z-[11]">
            Mã: {item?.product_code}
        </div>
        {item?.is_sale && <div className="product_sale">
            <Image
                priority={true}
                src={item.file ? environment.baseImageUrl + item?.file.image_url : "/asset/image/product_sale.svg"}
                alt={alt_def}
                title={alt_def}
                className="m-auto"
                width={100}
                height={40}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            />
        </div>}
    </div>


}

export default Item;